.bid-statement {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;
  /* justify-content:space-evenly; */
}

.bid-statement-actual-bid {
  width: 60%;
  border: 1px solid white;
  padding: 5px 3px 3px 4px;
  border-radius: 2px;
  cursor: pointer;
}

.bid-button button {
  background: linear-gradient(135deg, #ff4d4d, #ff6666);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.bid-button button:hover {
  background: linear-gradient(135deg, #ff6666, #ff4d4d);
}

.bid-button button:active {
  transform: scale(0.98);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .bid-statement {
    flex-direction: column;
  }
  .bid-statement-actual-bid {
    width: 100%;
  }
}
