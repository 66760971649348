.wrap-input-9 {
  width: 80%;
  margin: 10px 3%;
  position: relative;
}

.wrap-input-9 .input {
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  background-color: #f5f6fd;
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
  transition: 0.4s;
}

.wrap-input-9 .input:focus {
  outline: none;
}

.wrap-input-9 .focus-border:before,
.wrap-input-9 .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #6c5dd3;
  transition: 0.2s;
  transition-delay: 0.2s;
}

.wrap-input-9 .focus-border:after {
  top: auto;
  bottom: 0;
  right: auto;
  left: 0;
  transition-delay: 0.6s;
}

.wrap-input-9 .focus-border i:before,
.wrap-input-9 .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #5ab9ea;
  transition: 0.2s;
}

.wrap-input-9 .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  transition-delay: 0.4s;
}

.wrap-input-9 .input:focus ~ .focus-border:before,
.wrap-input-9 .input:focus ~ .focus-border:after {
  width: 100%;
  transition: 0.2s;
  transition-delay: 0.6s;
}

.wrap-input-9 .input:focus ~ .focus-border:after {
  transition-delay: 0.2s;
}

.wrap-input-9 .input:focus ~ .focus-border i:before,
.wrap-input-9 .input:focus ~ .focus-border i:after {
  height: 100%;
  transition: 0.2s;
}

.wrap-input-9 .input:focus ~ .focus-border i:after {
  transition-delay: 0.4s;
}

.input::placeholder {
  font-weight: 600;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
}

/* STYLES FOR THE BUTTON */
.btn-81,
.btn-81 *,
.btn-81 :after,
.btn-81 :before,
.btn-81:after,
.btn-81:before {
  border: 0 solid;
  box-sizing: border-box;
}

.btn-81 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: black;
  color: white !important;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
}

.btn-81:disabled {
  cursor: default;
}

.btn-81:-moz-focusring {
  outline: auto;
}

.btn-81 svg {
  display: block;
  vertical-align: middle;
}

.btn-81 [hidden] {
  display: none;
}

.btn-81 {
  border: 2px solid;
  border-radius: 999px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 1.2rem 3rem;
  position: relative;
  text-transform: uppercase;
}

.btn-81 span {
  font-weight: 900;
  mix-blend-mode: difference;
}

.btn-81:before {
  background-color: #2575fc;
  color: white !important;
  color: transparent;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
}

.btn-81:hover:before {
  color: white !important;
  -webkit-animation: progress-bar 1.6s;
  animation: progress-bar 1.6s;
}

@-webkit-keyframes progress-bar {
  0% {
    height: 0%;
    opacity: 1;
  }
  10% {
    height: 15%;
    opacity: 1;
  }
  25% {
    height: 25%;
    opacity: 1;
  }
  40% {
    height: 35%;
    opacity: 1;
  }
  55% {
    height: 75%;
    opacity: 1;
  }
  60% {
    height: 100%;
    opacity: 1;
  }
  to {
    height: 100%;
    opacity: 0;
  }
}

@keyframes progress-bar {
  0% {
    height: 0%;
    opacity: 1;
  }
  10% {
    height: 15%;
    opacity: 1;
  }
  25% {
    height: 25%;
    opacity: 1;
  }
  40% {
    height: 35%;
    opacity: 1;
  }
  55% {
    height: 75%;
    opacity: 1;
  }
  60% {
    height: 100%;
    opacity: 1;
  }
  to {
    height: 100%;
    opacity: 0;
  }
}

.link-div-tags{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top:10px;
}

/* RESPONSIVENESS */

/* FOR MOBILE DEVICES */

@media screen and (max-width: 568px) {
  .div-title {
    flex-direction: column !important;
  }
  .logo-image-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  /* .wrap-input-9 {
    width:96%;
  } */

  .motto {
    font-size: 11px !important;
    font-weight: 500;
  }

  .main-regsiter-section {
    width: 90% !important;
  }

  .main-login-section {
    width: 96% !important;
  }

  .form-register {
    width: 100% !important;
  }

  .div-inputs-main-container {
    margin-top: 20px !important;
  }

  .wrap-input-9 {
    width: 90%;
  }
  .p-link {
    text-align: center !important;
  }
}

@media only screen and (min-width: 568px) and (max-width: 912px) {
  .main-regsiter-section {
    width: 80% !important;
  }
}
