.nav_side {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sidebar-title {
  display: flex;
  flex-direction: row;

  justify-content: center;

  margin-top: 20px;
  margin-left: 30px;
}

.logo-sidebar-img {
  width: 60px;
  object-fit: cover;
  cursor: pointer;
}

.sidebar-legit-title h3 {
  margin-left: 20px;
  font-size: 20px;
  font-weight: 600;
  color: black;
  text-align: center;
  margin-top: 20px;
}

.navbar {
  width: 100%;
  background-color: #ffffff;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(86, 128, 233, 0.5);
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.left-nav {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu-div {
  margin-left: 25px;
}

.menu {
  font-size: 23px;
  cursor: pointer;
  display: none;
}

.wallet {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-top: 6px;
  width: 159px;

  position: relative;
}

.wallet-div {
  background-color: #5680e9;
  padding: 8px;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  box-shadow: 0px 2px 4px rgba(86, 128, 233, 0.5);
  height: 40px;
  width: 100%;
}

.wallet-p,
.wallet-dropdown {
  text-align: center;
}

.dropdown-div {
  min-height: 50px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #5680e9;
  padding: 2px 1px 2px 1px;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  box-shadow: 0px 2px 4px rgba(86, 128, 233, 0.5);
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-div-- {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.deposit-icon {
  font-size: 19px;
  cursor: pointer;
}

.withdraw {
  margin-left: 14px;
}

.records {
  margin-left: 2px;
}

.deposit-div-- p {
  font-size: 17px;
  font-weight: 600;
}

.right-nav {
  width: 20%;

  margin-right: 20px;

  display: flex;
  flex-direction: column;
  margin-top: 150px;
  gap: 15px;
  align-items: center;
  min-height: 200px;
  position: relative;
}

.right-nav-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.person-icon {
  width: 50px;
  height: 50px; 
  object-fit: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right:10px;
}
.user-name {
  font-weight: 600;
}

.profile-dropdown {
  margin-top: 5px;
}

.right-nav-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: #5680e9;
  padding: 13px;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  box-shadow: 0px 2px 4px rgba(86, 128, 233, 0.5);
  position: absolute;
  top: 65px;
}

.profile {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.profile-dropdown-icon {
  font-size: 18px;
}

@media screen and (max-width: 568px) {
  .navbar {
    width: 100%;
    gap: 10px;
    justify-content: flex-start;
  }
  .left-nav {
    gap: 10px;
  }

  .sidebar-title {
    margin-left: 0px;
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
  .sidebar-legit-title h3 {
    margin-left: 0;
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 15px;
  }

  .logo-sidebar-img {
    width: 40px;
  }
  .menu-div {
    margin-left: 5px;
  }
  .menu {
    display: block;
    font-size: 17px;
    cursor: pointer;
  }

  .wallet-div {
    height: 50px;
    padding: 10px;
  }

  .wallet-dropdown {
    display: none;
  }
  .dropdown-div {
    height: 85px;
    gap: 5px;
  }

  .dropdown-div-- {
    gap: 2px;
  }

  .wallet {
    margin-left: 50px;
  }

  .right-nav {
    margin-left: 10px;
    align-items: flex-start;
    margin-top: 120px;
  }

  .right-nav-main {
    flex-direction: column;
    gap: 0px;
  }
  .user-name {
    font-size: 12px;
  }
  .profile-dropdown {
    display: none;
  }

  .right-nav-dropdown {
    top: 80px;
    right: 10px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 912px) {
  .left-nav {
    width: auto;
  }
  .right-nav {
    width: auto;
  }
  .wallet {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .left-nav {
    width: auto;
  }
  .right-nav {
    width: auto;
  }
  .wallet {
    margin-left: 50px;
  }
}
