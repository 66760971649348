.sidebar-wrapper {
  overflow-y: auto;
}

.sidebar {
  width: 100%;
  min-height: 100vh;
  /* background-color: #5680e9; */
  background: linear-gradient(135deg, #3399ff, #66ccff);

  border-radius: 2px;
}

.sidebar-section {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  gap: 20px;
  white-space: nowrap;
}

.sidebar-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.sidebar-icon {
  font-size: 20px;
  cursor: pointer;
  margin-top: 2px;
  color: white;
}

.h3-text {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  color: white;
}

.count {
  width: 55px;
  display: flex;
  justify-content: center;
}

.count-bids-div {
  width: 55px;
  display: flex;
  justify-content: center;
  margin-left: 55px;
}

.count-progress {
  width: 55px;
  display: flex;
  justify-content: center;
  margin-left: 20px;
}

.count-revision {
  width: 55px;
  display: flex;
  justify-content: center;
  margin-left: 45px;
}
.count-completed {
  width: 55px;
  display: flex;
  justify-content: center;
  margin-left: 22px;
}
.count-disputed {
  width: 55px;
  display: flex;
  justify-content: center;
  margin-left: 38px;
}
.count-rating {
  width: 55px;
  display: flex;
  justify-content: center;
  margin-left: 60px;
}

.count-progress-h1 {
  color: white;
  font-weight: bold;
  font-size: 21px;
  text-align: center;
}

.count-reviews {
  width: 55px;
  display: flex;
  justify-content: center;
  margin-left: 35px;
}
