.gig-details {
  width: 95%;

  min-height: 50vh;
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* justify-content: space-between; */
  margin-top: 50px;
}

.bids {
  width: 60%;
  height: 100%;
  background: linear-gradient(135deg, #3399ff, #66ccff);
  color: white;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.job-name p,
.files p {
  font-size: 20px;
  font-weight: 600;
}

.job-description {
  margin-top: 20px;
  max-height: 250px;
  overflow-y: auto;
}

.place-bid {
  margin-top: 60px;
}

.wrap-input-8 .input {
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  background-color: #f5f6fd;
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
  transition: 0.4s;
  resize: none;
  /* border-radius: 2px; */
}

.wrap-input-8 .input:focus {
  outline: none;
}

.wrap-input-8 {
  width: 80%;
  margin: 10px 1%;
  position: relative;
}

.wrap-input-8 .input ~ .focus-border:before,
.wrap-input-8 .input ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #6c5dd3;
  transition: 0.3s;
}
.wrap-input-8 .input ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.wrap-input-8 .input ~ .focus-border i:before,
.wrap-input-8 .input ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #6c5dd3;
  transition: 0.4s;
}
.wrap-input-8 .input ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.wrap-input-8 .input:focus ~ .focus-border:before,
.wrap-input-8 .input:focus ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
.wrap-input-8 .input:focus ~ .focus-border i:before,
.wrap-input-8 .input:focus ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}

.btn-54,
.btn-54 *,
.btn-54 :after,
.btn-54 :before,
.btn-54:after,
.btn-54:before {
  border: 0 solid;
  box-sizing: border-box;
}
.btn-54 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
}
.btn-54:disabled {
  cursor: default;
}
.btn-54:-moz-focusring {
  outline: auto;
}
.btn-54 svg {
  display: block;
  vertical-align: middle;
}
.btn-54 [hidden] {
  display: none;
}
.btn-54 {
  box-sizing: border-box;
  display: block;
  font-weight: 900;
  padding: 1.8rem 5rem;
  position: relative;
  text-transform: uppercase;
  background: none;
}
.btn-54 .content,
.btn-54 .depth,
.btn-54 .shadow {
  transition: transform 0.2s;
}
.btn-54 .content {
  background: #fff;
  color: #000;
  display: grid;
  height: 84%;
  left: 0;
  place-items: center;
  position: absolute;
  top: 0;
  width: 100%;
}
.btn-54 .depth {
  background: #ccc;
  height: 10%;
  top: 84%;
}
.btn-54 .depth,
.btn-54 .shadow {
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
}
.btn-54 .shadow {
  background: #777;
  height: 6%;
  top: 94%;
}
.btn-54:hover .content {
  transform: translateY(4%);
}
.btn-54:hover .shadow {
  transform: translateY(-20%);
}
.btn-54:active .content {
  transform: translateY(8%);
}
.btn-54:active .shadow {
  transform: translateY(-30%);
}

.details {
  width: 40%;
  height: 100%;
  margin-left: 40px;
}

.specifics {
  background: linear-gradient(135deg, #3399ff, #66ccff);
  width: 400px;
  padding: 10px;
}

.specifics-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gig-specs {
  display: flex;
  border: 1px solid white;
  justify-content: space-around;
  padding: 3px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  color: white;
}

.overflow-value {
  overflow-x: auto;
  overflow-y: auto;
}

@media screen and (max-width: 568px) {
  .gig-details {
    flex-direction: column;
    margin-top: 20px;
  }

  .bids {
    width: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .details {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .gig-details {
    flex-direction: column;
    margin-top: 20px;
  }

  .bids {
    width: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .details {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .gig-details {
    flex-direction: column;
    margin-top: 20px;
  }

  .bids {
    width: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .details {
    width: 100%;
    /* width:600px; */
    margin-left: 0;
  }
}
