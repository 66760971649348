.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.file-upload-area {
  width: 80%;
  max-width: 600px;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.file-upload-area p {
  margin: 0;
}

.file-upload-choose {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-button {
  margin-top: 20px;
  padding: 10px 20px;
  background: linear-gradient(135deg, #ff4d4d, #ff6666);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.upload-button:hover {
  background: linear-gradient(135deg, #ff6666, #ff4d4d);
}

.upload-button:active {
  transform: scale(0.98);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.file-list {
  margin-top: 20px;
  width: 80%;
  max-width: 600px;
  text-align: left;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  /* background-color: #f9f9f9; */
  background: linear-gradient(135deg, #3399ff, #66ccff);
}

.file-item p {
  margin: 0;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-button {
  background: red;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.remove-button:hover {
  background: darkred;
}
