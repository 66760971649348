.main-dashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sidebar-render {
  width: 20%;

  /* position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #f7f7f7;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease; */

  transition: transform 0.3s ease;
}

.sidebar-render.open {
  transform: translateX(0);
}

/* STYLES FOR THE DASHBOARD */

.dashboard-render {
  width: 80%;
  min-height: 100vh;
}

.dashboard-render-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.main-flex-container {
  margin-top: 20px;
  width: 95%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.flex-item {
  display: flex;
  /* background-color: #007bff; */
  background: linear-gradient(135deg, #3399ff, #66ccff);

  color: white;
  font-weight: bold;
  margin: 0.4rem;
  min-width: 300px;
  min-height: 150px;
  box-sizing: border-box;
  cursor: pointer;
  /* box-shadow: 0px 2px 4px rgba(86, 128, 233, 0.5); */
  box-shadow: 0px 2px 4px rgba(136, 96, 208, 0.5);
  border-radius: 0.25rem;
}

.flex-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  width: 100%;
}

.flex-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard-icon {
  font-size: 40px;
  font-weight: bold;
}

.flex-count-p {
  font-size: 24px;
  font-weight: bold;
}

.subjects {
  margin-top: 20px;
  width: 95%;
  /* background-color: #007bff; */
  background: linear-gradient(135deg, #3399ff, #66ccff);

  color: white;
  font-weight: bold;
  min-height: 20vh;
  box-shadow: 0px 2px 4px rgba(136, 96, 208, 0.5);
  border-radius: 0.25rem;
}

.subjects-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.subjects-list {
  display: flex;
  flex-wrap: wrap;
  list-style: disc;
  padding-left: 20px;
}

.subjects-list li {
  margin-right: 20px;
}

@media screen and (max-width: 568px) {
  .sidebar-render {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #333;
    transform: translateX(-100%);
    margin-top: 30px;
  }

  .sidebar-render.open {
    transform: translateX(0);
  }
  .dashboard-render {
    width: 100%;
  }
  .main-flex-container {
    width: 85%;
  }

  .dashboard-render-container {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  .subjects-flex-container {
    padding: 15px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .sidebar-render {
    width: 250px;
  }

  .flex-item {
    min-width: 200px;
  }
  .flex-container {
    margin-left: 20px;
    justify-content: flex-start;
  }

  .subjects {
    min-height: 12vh;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .sidebar-render {
    width: 250px;
  }
}
