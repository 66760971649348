.profile-main {
  margin-top: 10px;
  width: 75%;
  height: 95%;
  background: linear-gradient(135deg, #3399ff, #66ccff);
  border-radius: 3px;
}

.profile-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.selected-image-name{
  text-align: center;
  font-size: 12px;
  color:white;
}

.profile-pic {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-pic-image {
  height: 150px;
  object-fit: cover;
  width: 170px;
  border-radius: 10px;
  cursor:pointer;
}

.choose-image {
  margin-top: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.rating-profile {
  margin-top: 30px;
  color: white;
  font-weight: bold;
}

.rating-number {
  margin-left: 4px;
}

.bio {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-right: 20px;
}

.actual-bio {
  margin-bottom: 20px;
}

.bio-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.bio-textarea::placeholder {
  font-weight: 600;
}

.update-profile input {
  width: 300px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 13px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.update-profile input:focus {
  border-color: #007bff;
  outline: none;
}

.subject-specific {
  width: 100%;
}

.wrap-check-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.wrap-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.wrap-check input[type="checkbox"] {
  display: none;
}

.wrap-check .check-box {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-weight: 600;
  color: white;
}

.wrap-check .check-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 5px;
  background-color: transparent;
}

.wrap-check input[type="checkbox"]:checked + .check-box::before {
  background-color: #34b93d;
}

.wrap-check .check-box::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  background-color: white;
  opacity: 0;
  transition: opacity 0.2s;
}

.wrap-check input[type="checkbox"]:checked + .check-box::after {
  opacity: 1;
}

.save-profile-button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.btn-52,
.btn-52 *,
.btn-52 :after,
.btn-52 :before,
.btn-52:after,
.btn-52:before {
  border: 0 solid;
  box-sizing: border-box;
}
.btn-52 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
}
.btn-52:disabled {
  cursor: default;
}
.btn-52:-moz-focusring {
  outline: auto;
}
.btn-52 svg {
  display: block;
  vertical-align: middle;
}
.btn-52 [hidden] {
  display: none;
}
.btn-52 {
  border: 1px solid;
  border-radius: 999px;
  box-sizing: border-box;
  display: block;
  font-weight: 900;
  overflow: hidden;
  padding: 1.2rem 3rem;
  position: relative;
  text-transform: uppercase;
}
.btn-52 .original {
  background: #fff;
  color: #000;
  display: grid;
  inset: 0;
  place-content: center;
  position: absolute;
  transition: transform 0.2s;
}
.btn-52:hover .original {
  transform: translateY(100%);
}
.btn-52 .letters {
  display: inline-flex;
}
.btn-52 span {
  opacity: 0;
  transform: translateY(-15px);
  transition: transform 0.2s, opacity 0.2s;
}
.btn-52:hover span {
  opacity: 1;
  transform: translateY(0);
}
.btn-52:hover span:nth-child(2) {
  transition-delay: 0.1s;
}
.btn-52:hover span:nth-child(3) {
  transition-delay: 0.2s;
}
.btn-52:hover span:nth-child(4) {
  transition-delay: 0.3s;
}
.btn-52:hover span:nth-child(5) {
  transition-delay: 0.4s;
}
.btn-52:hover span:nth-child(6) {
  transition-delay: 0.5s;
}

@media screen and (max-width: 568px) {
  .profile-main {
    height: 95%;
  }
  .profile {
    width: 100%;
    height: 100%;
  }
  .profile-dashboard-render {
    margin-top: 0px;
  }
  .profile-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bio {
    width: 100%;
    /* margin-left:auto; */
    /* margin-right:auto; */
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-left: 40px;
  }
  .bio-textarea {
    width: 80%;
  }
  .profile-pic {
    width: 100%;
    margin-top: 20px;
  }
  .bio {
    width: 100%;
  }
  .save-profile-button {
    justify-content: center;
    margin-bottom: 20px;
  }
  .update-profile input {
    width: 250px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .profile-main {
    width: 90%;
    height: 90%;
  }
  .profile-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bio {
    width: 100%;
    /* margin-left:auto; */
    /* margin-right:auto; */
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-left: 40px;
  }
  .bio-textarea {
    width: 90%;
  }
  .profile-pic {
    width: 100%;
    margin-top: 10px;
  }
  .bio {
    width: 100%;
  }
  .save-profile-button {
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .profile-main {
    width: 80%;
    height: 90%;
  }
  .profile-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bio {
    width: 100%;
    /* margin-left:auto; */
    /* margin-right:auto; */
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-left: 40px;
  }
  .bio-textarea {
    width: 90%;
  }
  .profile-pic {
    width: 100%;
    margin-top: 10px;
  }
  .bio {
    width: 100%;
  }
  .save-profile-button {
    justify-content: center;
  }
}
